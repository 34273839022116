import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// markup
const NotFoundPage = () => {
  return (
    <MainPage>
      <title>Not found</title>
      <Heading>Page not found</Heading>
      <Paragraph>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <Code>src/pages/</Code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </Paragraph>
    </MainPage>
  );
};

const MainPage = styled.main`
  padding: 96px;
`;

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 64px;
  max-width: 320px;
`;

const Paragraph = styled.p`
  margin-bottom: 48px;
`;

const Code = styled.code`
  color: #8a6534;
  padding: 4px;
  background-color: #fff4db;
  font-size: 1.25rem;
  border-radius: 4px;
`;

export default NotFoundPage;
